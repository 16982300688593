import { createGlobalStyle } from 'styled-components';
import { base, FONT_STRING } from './fonts';
import COLORS from './colors';

import { GRID_WIDTH } from './grid';
import { breakpointSizes } from './breakpoints';

// reset CSS + injecting fonts
// DO NOT USE THIS FILE TO OVERWRITE STYLING OF CSS CLASSES
const style = createGlobalStyle`

  -webkit-font-smoothing: antialiased;

  :root {
    --gutter: 30px;
    --gutter-side: 15px;

    --gutter: min(30px, calc(100vh * ${30 / GRID_WIDTH}));
    --gutter-side: max(30px, calc(100vh * ${30 / GRID_WIDTH} * 0.2));
  }

  @media screen and (max-width: ${breakpointSizes.medium}px) {
    :root {
      --gutter-side: max(20px, calc(100vh * ${30 / GRID_WIDTH} * 0.2));
    }
  }

  #__next {
      overflow-x: clip;
  }

  ::selection {
    background: ${COLORS.brand.regular.css};
    color: ${COLORS.white.css};
    opacity: 1;
  }
  ::-moz-selection {
    background: ${COLORS.brand.regular.css};
    color: ${COLORS.white.css};
    opacity: 1;
  }

  html {
    scroll-behavior: smooth;   
  }
    
  html, body {
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: ${FONT_STRING};
    font-weight: 400;
    font-size: ${base}px;
    background-color: ${COLORS.white.css};
    color: ${COLORS.text.regular.css};
   
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -ms-text-size-adjust: none;
    -webkit-text-size-adjust: none;
  }

  body {
    background: ${COLORS.blocks.body.css}
  }

  * {
    box-sizing: border-box;
  }

  a {
    position: relative;
    color: inherit;
    text-decoration: none;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
  }

  ul, ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

`;

export default style;
